import { jobAPI } from '@/api';
import showAPIErrorToast from '@/utils/showAPIErrorToast';

const handleErrorResponse = (err, dispatch, disableRequestIndicator = false) => {
    if (!disableRequestIndicator) {
        showAPIErrorToast(err);
        dispatch('SET_API_RESPONSE_STATUS', 'error');
    }
};

const initialState = {
    jobs: [],
    job: null,
    apiResponseStatus: '',
};

const getters = {
    jobs: (state) => {
        return state.jobs;
    },
    job: (state) => {
        return state.job;
    },
    apiResponseStatus: ({ apiResponseStatus }) => {
        return apiResponseStatus;
    },
};

const mutations = {
    SET_JOBS(state, jobs) {
        state.jobs = jobs;
    },
    SET_JOB(state, job) {
        state.job = job;
    },
    SET_API_RESPONSE_STATUS(state, apiResponseStatus) {
        state.apiResponseStatus = apiResponseStatus;
    },
};

const actions = {
    async FETCH_JOBS({ dispatch, rootState }) {
        dispatch('SET_API_RESPONSE_STATUS', 'pending');
        const options = {
            headers: { Authorization: `Bearer ${rootState.app.token}` },
        };
        jobAPI
            .get(`${jobAPI.defaults.endpoints.getJobs}?createdBy=${rootState.app.user._id}`, options)
            .then(({ data }) => {
                dispatch('SET_JOBS', data);
                dispatch('SET_API_RESPONSE_STATUS', 'success');
            })
            .catch((err) => {
                handleErrorResponse(err, dispatch);
            });
    },
    async FETCH_JOBS_BY_ACCOUNT_ID({ dispatch, rootState }) {
        dispatch('SET_API_RESPONSE_STATUS', 'pending');
        const options = {
            headers: { Authorization: `Bearer ${rootState.app.token}` },
        };

        jobAPI
            .get(`${jobAPI.defaults.endpoints.getJobs}account?accountId=${rootState.app.user.account_id}`, options)
            .then(({ data }) => {
                dispatch('SET_JOBS', data);
                dispatch('SET_API_RESPONSE_STATUS', 'success');
            })
            .catch((err) => {
                handleErrorResponse(err, dispatch);
            });
    },
    async FETCH_JOB({ dispatch, rootState }, jobId) {
        dispatch('SET_API_RESPONSE_STATUS', 'pending');
        const options = {
            headers: { Authorization: `Bearer ${rootState.app.token}` },
        };
        await jobAPI
            .get(`${jobAPI.defaults.endpoints.getJobDetails}${jobId}`, options)
            .then(({ data }) => {
                dispatch('SET_JOB', data);
                dispatch('SET_API_RESPONSE_STATUS', 'success');
            })
            .catch((err) => {
                handleErrorResponse(err, dispatch);
            });
    },
    SET_JOBS({ commit }, jobs) {
        commit('SET_JOBS', jobs);
    },
    SET_JOB({ commit }, job) {
        commit('SET_JOB', job);
    },
    SET_API_RESPONSE_STATUS({ commit }, apiResponseStatus) {
        commit('SET_API_RESPONSE_STATUS', apiResponseStatus);
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    mutations,
    actions,
};
